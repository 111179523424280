import { Step2 } from 'assets';
import { Alert, Container, FormSubmit, Loading } from 'components';
import { Link, navigate } from 'gatsby';
import { FormattedMessage, useIntl } from 'gatsby-plugin-intl';
import { ClientData } from 'models';
import { OrDivider } from 'modules/authentication';
import { CartThunks, ClientInformation } from 'modules/cart';
import { AuthService } from 'modules/firebase';
import { SuccessToast } from 'modules/inbox';
import { AppDispatch, ApplicationState } from 'modules/redux-store';
import React, { lazy, Suspense, useEffect, useState } from 'react';
import Geocode from 'react-geocode';
import { FormProvider, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { toast, ToastContainer } from 'react-toastify';
import { buttons, cart } from 'styles';

const UserInfo = lazy(() => import('modules/cart/components/UserInfo'));
const DeliveryInfo = lazy(() => import('modules/cart/components/DeliveryInfo'));
const BusinessInfo = lazy(() => import('modules/cart/components/BusinessInfo'));

const authService = AuthService();

const OfferInformation: React.FC = () => {
  const dispatch = useDispatch<AppDispatch>();
  const { formatMessage } = useIntl();

  const { isLoggedIn, user: userState } = useSelector(
    (state: ApplicationState) => state.auth,
  );
  const [user, setUser] = useState<ClientData>();
  const [isConsent, setIsConsent] = useState<boolean>(true);
  const [isValidAddress, setIsValidAddress] = useState<boolean>(true);

  const handleConsentChange = (event: React.MouseEvent<HTMLDivElement>) => {
    event.preventDefault();
    setIsConsent(!isConsent);
  };

  async function getUserData() {
    const data = await authService.getUserData();

    setUser({
      userInfoData: {
        firstName: data?.firstName,
        lastName: data?.lastName,
        email: data?.email,
        phone: data?.phone,
      },
      deliveryData: {
        address: data?.address,
        location: data?.location,
        postCode: data?.postCode,
        city: data?.city,
        country: data?.country,
        specialRemark: data?.specialRemark,
      },
      bussiness: data?.business,
    });
  }

  useEffect(() => {
    if (!isLoggedIn) navigate('/kosarica/prijava');
    getUserData();
  }, [isLoggedIn]);

  const methods = useForm<ClientInformation>({
    mode: 'onBlur',
    reValidateMode: 'onBlur',
  });

  const pageLayout =
    userState && !userState.emailVerified
      ? cart.page
      : cart.pageNoEmailVerificationMessage;

  if (!isLoggedIn) {
    return <Loading isLoading fullPage />;
  }

  return (
    <Container css={pageLayout}>
      {!isLoggedIn && (
        <Alert
          type="warning"
          text={formatMessage({ id: 'cart.data.alert.warning' })}
        />
      )}
      <div>
        <div css={cart.stepTitle}>
          <Step2 />
          <p css={cart.step}>
            <FormattedMessage id="shared.step" values={{ step: 2 }} />
          </p>
        </div>
      </div>
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <div css={cart.distributorHeader}>
            <h2 css={cart.pageTitle}>
              <FormattedMessage id="cart.data.request-offer" />
            </h2>
          </div>
          {user && (
            <Suspense fallback={<Loading isLoading />}>
              <DeliveryInfo
                deliveryData={user?.deliveryData}
                isValidAddress={isValidAddress}
              />

              <UserInfo
                userInfoData={user?.userInfoData}
                isConsent={isConsent}
                handleConsentChange={handleConsentChange}
                isUserAnon={userState?.isAnonymous}
              />

              <BusinessInfo
                bussinesData={user?.bussiness}
                isUserAnon={userState?.isAnonymous}
              />
            </Suspense>
          )}

          <section css={cart.infoSection}>
            <div />

            <div css={cart.infoCta}>
              <div>
                <FormSubmit
                  text={formatMessage({ id: 'cart.data.next-step' })}
                  disabled={!isLoggedIn || !isConsent}
                />
              </div>
              <OrDivider />
              <Link to="/" css={[buttons.container, cart.declineButton]}>
                <FormattedMessage id="cart.data.give-up" />
              </Link>
            </div>
          </section>
        </form>
      </FormProvider>
      <ToastContainer
        position="bottom-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop
        rtl={false}
        pauseOnFocusLoss
        closeButton={false}
        style={{
          width: '70%',
        }}
        toastStyle={{
          borderRadius: '4px',
        }}
      />
    </Container>
  );

  function onSubmit(data: ClientInformation) {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { contactConsent, ...clientInfo } = data;

    Geocode.setApiKey(process.env.GATSBY_API_KEY || '');

    Geocode.fromAddress(
      clientInfo.address +
        ' ' +
        clientInfo.postCode +
        ' ' +
        clientInfo.city +
        ' ' +
        clientInfo.country,
    )
      .then(() => {
        dispatch(CartThunks.addClientInfo({ ...clientInfo }));
        navigate('/kosarica/odabir-distributera');
      })
      .catch(() => {
        setIsValidAddress(false);
        toast.error(
          <SuccessToast
            title={formatMessage({ id: 'cart.submit.error.title' })}
            text={formatMessage({ id: 'cart.submit.error.text' })}
            showSuccessIcon={false}
          />,
        );
        window.scrollTo(0, 0);
      });
  }
};

export default OfferInformation;
